import React from "react";
import { useFormState } from "react-use-form-state";
import { ToastContainer, toast } from "react-toastify";
import { LoginStyles, ButtonPrimary, InputLogin, } from './LoginStyle'
import { AuthContext } from "../../context";
import { InputSelect } from "../Dashboard/AsignarServicio/style";
import permis from "../Dashboard/Libs/permis";



export default function Register() {
  const [formState, { text, password, email, select }] = useFormState({});
  const { signIn } = React.useContext(AuthContext);

  const registerUser = () => {
    console.log(formState.values)

    fetch("https://backendsapco.herokuapp.com/sapco/register", {
      method: 'POST',
      body: JSON.stringify(formState.values),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => res.json())
      .then((response) => {
        console.log(response)
        if (response.error) {
          toast.error("Ups, error al registrar", {
            autoClose: 1000,
          });
        } else {
          console.log('hola')
          localStorage.setItem("sapcoUser", JSON.stringify(response));
          signIn();
        }
      });
  }

  return (
    <LoginStyles>
      <ToastContainer />
      <img src="images/logonuevo.png" alt="Logo Sapco" />
      <h3>Salvando al mundo de accidentes de tránsito </h3>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          registerUser();
        }}
        autoComplete="off"
      >
        <InputLogin {...text("nombre")} placeholder="Nombre completo" required />

        <InputLogin
          placeholder="Cédula de ciudadania"
          {...text("cc")}
          required
        />

        <InputLogin
          type="email"
          placeholder="Correo electrónico"
          {...email("email")}
          required
        />


        <InputLogin
          placeholder="Ciudad"
          {...text("ciudad")}
          required
        />

        <InputSelect {...select("permisos")} required>
          <option value="">Dar permiso</option>
          {permis.map((item, i) => (
            <option key={i} value={item}>{item}</option>
          ))}
        </InputSelect>
        
        <InputLogin placeholder="Contraseña" {...password("contrasena")} required />

        <ButtonPrimary login>Registra usuario</ButtonPrimary>
      </form>
    </LoginStyles>
  );
}

