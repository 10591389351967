import React, { useState, useEffect} from "react";
import { Link, useHistory } from "react-router-dom";
import {
  NavigationStyles,
  HeaderStyles,
  NavigationDesktopStyles,
  DashboardStyles,
} from "./DashboardStyle";

export default function Home() {
  const [frase, setfrase] = useState("");
  const [serviceCount, setserviceCount] = useState([]);


  useEffect(() => {
    fetch("https://backendsapco.herokuapp.com/sapco/random")
      .then((response) => response.json())
      .then((json) => setfrase(json.name));

      fetch("https://backendsapco.herokuapp.com/sapco/dashboardcount")
      .then((response) => response.json())
      .then((json) => setserviceCount(json));
  }, []);

  return (
    <DashboardStyles>
      <div className="services">
        <h3>Servicios:</h3>
        <div className="grid-boxes">
          <div className="completed">
            <span>{serviceCount.totalCountSuccess ? serviceCount.totalCountSuccess : "0"}</span>
            Finalizados
          </div>
          <div className="process">
            <span>{serviceCount.totalCountProcess ? serviceCount.totalCountProcess : "0"}</span>
            En proceso
          </div>
        </div>
      </div>

      <p className="sentence">
        {frase}
      </p>
    </DashboardStyles>
  );
}
