import React from "react";
import { Link } from "react-router-dom";
import { CollectionsStyles } from "./style";

export default function Colecciones() {
  return (
    <CollectionsStyles>
      <div className="titles">
        <h3>Colecciones</h3>
        {/*<Link to="/collections/item">
          <span>
            <ButtonLink>Crear</ButtonLink>
          </span>
  </Link>*/}
      </div>

      <div className="collectionType">
        <Link to="/colecciones/servicios">
          Tipos de Servicio
          <span>Se requiere en la asignación de servicios</span>
        </Link>

        <Link to="/colecciones/frases">
          Frases
          <span>Salen aletoriamente en el Dashboard</span>
        </Link>

        <Link to="/colecciones/ciudades">
          Ciudades
          <span>Necesarias para crear usuarios</span>
        </Link>
      </div>
    </CollectionsStyles>
  );
}
