const Brands = [
    'AUDI',
    'BMW',
    'DINA',
    'BUICK',
    'CADILLAC',
    'CHEVROLET',
    'CHRYSLER',
    'CITROEN',
    'DACIA',
    'DAEWOO',
    'DAIHATSU',
    'DODGE',
    'FIAT',
    'FORD',
    'HYUNDAI',
    'HONDA',
    'ISUZU',
    'JAGUAR',
    'JEEP',
    'KIA',
    'LANCIA',
    'MAZDA',
    'MERCEDES BENZ',
    'MINI',
    'MERCURY',
    'MG',
    'MITSUBISHI',
    'NISSAN',
    'PEGASO',
    'PEUGEOT',
    'PONTIAC',
    'PORSCHE',
    'RENAULT',
    'SEAT',
    'SSANGYONG',
    'SKODA',
    'SUBARU',
    'SUZUKI',
    'TOYOTA',
    'VOLKSWAGEN',
    'VOLVO',
    'AGRALE',
    'CHERY',
    'BYD',
    'GEELY',
    'INFINITI',
    'OPEL',
    'UNITED MOTORS',
    'JMC',
    'AZ',
    'ZOTYE',
    'HUMMER',
    'SAAB',
    'ACURA',
    'LEXUS',
    'LAND ROVER',
    'MASERATI',
    'LINCOLN',
    'BRILLIANCE',
    'SMART',
    'RAM',
  ];
  
  export default Brands;
  