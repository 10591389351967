import React, { useState, useEffect } from "react";
import Datatable from "react-data-table-component";
import styled from "styled-components";
import { useFormState } from "react-use-form-state";
import { TiTick, TiCancel } from "react-icons/ti";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Servicios() {
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [formState, { text }] = useFormState();
  const [data, setdata] = useState([]);
  const [recargar, setrecargar] = useState(true);

  const cambiarState = (id, statePrevious) => {
    fetch("https://backendsapco.herokuapp.com/sapco/changestateservice", {
      method: "POST",
      body: JSON.stringify({ id: id, estado: statePrevious }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log("Ups, error");
        } else {
          setrecargar(!recargar);
        }
      });
  };

  const columnas = [
    {
      name: "Fecha de Recogida",
      selector: "horaRecogida",
      sortable: true,
    },
    {
      name: "Nombre",
      selector: "nombreCliente",
      sortable: true,
    },
    {
      name: "Contacto",
      selector: "numeroContacto",
      sortable: true,
    },
    {
      name: "Origen",
      selector: "origen",
      sortable: true,
    },
    {
      name: "Destino",
      selector: "destino",
      sortable: true,
    },
    {
      name: "Placa",
      selector: "placa",
      sortable: true,
    },
    {
      name: "Marca",
      selector: "marca",
      sortable: true,
    },
    {
      name: "Ciudad",
      selector: "ciudad",
      sortable: true,
    },
    {
      name: "Estado",
      cell: (row) => (
        <>
          {row.estado === "Iniciado" && (
            <span
              style={{
                backgroundColor: "green",
                borderRadius: "5px",
                padding: "5px",
                color: "white",
              }}
            >
              Iniciado
            </span>
          )}
          {row.estado === "Finalizado" && (
            <span
              style={{
                backgroundColor: "blue",
                borderRadius: "5px",
                padding: "5px",
                color: "white",
              }}
            >
              Finalizado
            </span>
          )}
          {row.estado === "Cancelado" && (
            <span
              style={{
                backgroundColor: "red",
                borderRadius: "5px",
                padding: "5px",
                color: "white",
              }}
            >
              Cancelado
            </span>
          )}
        </>
      ),
      selector: "_id",
    },
    {
      name: "Acciones",
      cell: (row) => (
        <>
          {row.estado === "Iniciado" ? (
            <>
              <span
                className="add"
                title="Finalizar"
                data-id={row._id}
                onClick={() => cambiarState(row._id, "Finalizado")}
              >
                <TiTick style={{ fontSize: "2.6rem" }} />
              </span>
              <span
                className="delete"
                title="Cancelar"
                data-id={row._id}
                onClick={() => cambiarState(row._id, "Cancelado")}
              >
                <TiCancel style={{ fontSize: "2.6rem" }} />
              </span>
            </>
          ) : null}
        </>
      ),
      selector: "_id",
    },
  ];

  useEffect(() => {
    fetch("https://backendsapco.herokuapp.com/sapco/services")
      .then((response) => response.json())
      .then((json) => {
        //console.log(json)
        setdata(json);
      });
  }, [recargar]);

  const handleClear = () => {
    setResetPaginationToggle(!resetPaginationToggle);
    formState.clear();
  };

  const FilterComponent = () => (
    <>
      <InputLogin
        id="search"
        type="text"
        className="search"
        placeholder="Filtrar por Nombre, Email"
        {...text("search")}
      />
      <ClearSearch className="clear-search" onClick={handleClear}>
        X
      </ClearSearch>
    </>
  );

  const filteredItems = data.filter(
    (item) =>
      (item.name && item.name.includes(formState.values.search)) ||
      (item.email && item.email.includes(formState.values.search))
  );

  const tableData = {
    columnas,
    data,
  };

  return (
    <ViewStyles>
      <div className="card-image">
        <br></br>
        
        <Datatable
          columns={columnas}
          data={data}
          export
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          paginationComponentOptions={{
            rowsPerPageText: "Cantidad de filas",
            rangeSeparatorText: "de",
          }}
          subHeader
        />
      </div>
      <ExcelFile element={<button style={{borderRadius:'5px', padding:'5px'}}>Descargar en Excel</button>}>
          <ExcelSheet data={data} name="Employees">
            <ExcelColumn label="Fecha de Recogida" value="horaRecogida" />
            <ExcelColumn label="Nombre del cliente" value="nombreCliente" />
            <ExcelColumn label="Contacto" value="numeroContacto" />
            <ExcelColumn label="Origen" value="origen" />
            <ExcelColumn label="Destino" value="destino" />
            <ExcelColumn label="Placa" value="placa" />
            <ExcelColumn label="Marca" value="marca" />
            <ExcelColumn label="Ciudad" value="ciudad" />
            <ExcelColumn label="Estado" value="estado" />
          </ExcelSheet>
        </ExcelFile>
    </ViewStyles>
  );
}

const InputLogin = styled.input`
  border: none;
  background: #e6e6e6;
  padding: 11px 24px;
  font-size: 1.4rem;
`;

const ClearSearch = styled.button`
  padding: 1.04rem;
  border: none;
  background: #c5c5c5;
  color: #3a3a3a;
`;

const ViewStyles = styled.div`
  .edit {
    background-color: #0aaf4c;
    color: #fff;
  }
  .delete {
    color: #fb4a4a;
  }
  .add {
    color: #0aaf4c;
  }
  .delete,
  .add {
    cursor: pointer;
    margin-left: 2rem;
    svg {
      pointer-events: none;
    }
  }
`;
