import React, { useState, useEffect } from "react";
import Datatable from "react-data-table-component";
import styled from "styled-components";
import { useFormState } from "react-use-form-state";
import { TiUserDeleteOutline, TiUserAddOutline } from "react-icons/ti";

export default function Servicios() {
  //const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [formState, { text }] = useFormState();
  const [data, setdata] = useState([]);
  const [recargar, setrecargar] = useState(true);

  const cambiarState = (id, statePrevious) => {
    let state = "";
    if (statePrevious === "Activo") {
      state = "Inactivo";
    } else {
      state = "Activo";
    }
    fetch("https://backendsapco.herokuapp.com/sapco/changestate", {
      method: "POST",
      body: JSON.stringify({ id: id, estado: state }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log("Ups, error");
        } else {
          setrecargar(!recargar);
        }
      });
  };

  const columnas = [
    {
      name: "Nombre",
      selector: "nombre",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      cell: (row) => (
        <span style={{ textTransform: "lowercase" }}>{row.email}</span>
      ),
    },
    {
      name: "Teléfono",
      selector: "telefono",
      sortable: true,
      cell: (row) => (
        <span style={{ textTransform: "lowercase" }}>
          {row.phone || "Vacio"}
        </span>
      ),
    },
    {
      name: "Ciudad",
      selector: "ciudad",
      sortable: true,
    },
    {
      name: "Identificación",
      selector: "cc",
      sortable: true,
    },
    {
      name: "Fecha de Creación",
      selector: "fechaCreacion",
      cell: (row) => new Date(row.fechaCreacion).toLocaleDateString("es-ES"),
      sortable: true,
    },
    {
      name: "Permiso",
      selector: "permisos",
      sortable: true,
    },
    {
      name: "Estado",
      selector: "estado",
      sortable: true,
    },
    {
      name: "Acciones",
      cell: (row) => (
        <>
          {row.estado === "Activo" && (
            <span
              className="delete"
              title="Inactivar"
              data-id={row._id}
              onClick={() => cambiarState(row._id, row.estado)}
            >
              <TiUserDeleteOutline style={{ fontSize: "2.6rem" }} />
            </span>
          )}
          {row.estado === "Inactivo" && (
            <span
              title="Activar"
              className="add"
              data-id={row._id}
              onClick={() => cambiarState(row._id, row.estado)}
            >
              <TiUserAddOutline style={{ fontSize: "2.6rem" }} />
            </span>
          )}
        </>
      ),
      selector: "_id",
    },
  ];

  useEffect(() => {
    fetch("https://backendsapco.herokuapp.com/sapco/users")
      .then((response) => response.json())
      .then((json) => setdata(json));
  }, [recargar]);

  const handleClear = () => {
    setResetPaginationToggle(!resetPaginationToggle);
    formState.clear();
  };

  const FilterComponent = () => (
    <>
      <InputLogin
        id="search"
        type="text"
        className="search"
        placeholder="Filtrar por Nombre, Email"
        {...text("search")}
      />
      <ClearSearch className="clear-search" onClick={handleClear}>
        X
      </ClearSearch>
    </>
  );

  const filteredItems = data.filter(
    (item) =>
      (item.name && item.name.includes(formState.values.search)) ||
      (item.email && item.email.includes(formState.values.search))
  );

  return (
    <ViewStyles>
      <div className="card-image">
        <Datatable
          columns={columnas}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          paginationComponentOptions={{
            rowsPerPageText: "Cantidad de filas",
            rangeSeparatorText: "de",
          }}
          subHeader
          subHeaderComponent={FilterComponent()}
        />
      </div>
    </ViewStyles>
  );
}

const InputLogin = styled.input`
  border: none;
  background: #e6e6e6;
  padding: 11px 24px;
  font-size: 1.4rem;
`;

const ClearSearch = styled.button`
  padding: 1.04rem;
  border: none;
  background: #c5c5c5;
  color: #3a3a3a;
`;

const ViewStyles = styled.div`
  .edit {
    background-color: #0aaf4c;
    color: #fff;
  }
  .delete {
    color: #fb4a4a;
  }
  .add {
    color: #0aaf4c;
  }
  .delete,
  .add {
    cursor: pointer;
    margin-left: 2rem;
    svg {
      pointer-events: none;
    }
  }
`;
