import styled from "styled-components";

const CollectionsStyles = styled.div`
  .titles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;

    a {
      background: #d3701f;
      color: #fff;
      display: inline-block;
      padding: 0.3rem 2rem;
      border-radius: 3px;
    }
  }
  .collectionType {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    a {
      background: #ddd;
      margin: 0.4rem 2rem;
      padding: 2rem;
      text-align: center;
      font-size: 1.8rem;
      border-radius: 2px;
      position: relative;
      text-decoration: none;
      color: #393939;
      cursor: pointer;

      :hover {
        background: #cccccc;
      }

      span {
        font-size: 1.2rem;
        margin-left: 1rem;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  .edit {
    background-color: ${(props) => props.theme.orange};
    color: #fff;
  }
  .wrapper-list {
    margin-top: 1rem;
  }
  .list {
    padding: 0.7rem 1.2rem;
    line-height: 1.8rem;
    background: #ececec;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    p {
      margin: 0;
    }
  }
  .user {
    font-size: 1.2rem;
  }

  .type {
    float: right;
  }
  .arrow-right {
    border-right: 2px solid ${(props) => props.theme.orange};
    border-bottom: 2px solid ${(props) => props.theme.orange};
  }
  .del {
    float: right;
    color: #ff4949;
    font-size: 2.6rem;
    position: relative;
    bottom: 10px;
    cursor: pointer;
    margin-right: 1rem;
    display: flex;

    .editValues {
      svg {
        color: #d2711f;
        font-size: 3.4rem;
        position: relative;
        bottom: 3px;
        margin-left: 1rem;
      }
    }
  }
  .newSource {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
    margin-bottom: 2rem;

    input {
      padding: 1rem;
      border-radius: 2px;
      border: 1px solid #a5a5a5;
      font-size: 1.5rem;
    }

    svg {
      font-size: 3.5rem;
      color: #d2711f;
      margin-right: 1rem;
      cursor: pointer;
    }
  }
`;

const ButtonLink = styled.span`
  background: ${(props) => props.theme.orange};
  color: #fff;
  display: inline-block;
  padding: 0.3rem 2rem;
  border-radius: 3px;

  &:hover {
    background-color: ${(props) => props.theme.orangeHover};
  }

  &[disabled] {
    opacity: 0.5;
  }
`;

const divColeccion = styled.a`
  background: #ddd;
  margin: 0.4rem 2rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.8rem;
  border-radius: 2px;
  position: relative;
`;

export { CollectionsStyles, ButtonLink, divColeccion };
