import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useFormState } from "react-use-form-state";
import { GoTrashcan } from "react-icons/go";
import { FiPlusCircle } from "react-icons/fi";
import { CollectionsStyles } from "./style";

export default function Ciudades() {
  const [formState, { text }] = useFormState();
  const [data, setdata] = useState([]);
  const [recargar, setrecargar] = useState(true);

  useEffect(() => {
    fetch("https://backendsapco.herokuapp.com/sapco/ciudades")
      .then((response) => response.json())
      .then((json) => setdata(json));
  }, [recargar]);

  const deleteSource = (id) => {
    fetch("https://backendsapco.herokuapp.com/sapco/delete-ciudades", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          toast.error("Ups, error al Eliminar", {
            autoClose: 1000,
          });
        } else {
          setrecargar(!recargar);
        }
      });
  };

  const saveSource = () => {
    if (formState.values.name !== "") {
      const value = JSON.parse(localStorage.getItem("sapcoUser"));
      let dataSend = {
        nombre: formState.values.name,
        tipo: "Ciudad",
        usuario: value.name,
        idUsuario: value._id,
      };

      fetch("https://backendsapco.herokuapp.com/sapco/ciudades", {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            toast.error("Ups, error al registrar", {
              autoClose: 1000,
            });
          } else {
            setrecargar(!recargar);
          }
        });
    }
  };

  return (
    <CollectionsStyles>
      <ToastContainer />
      <div className="titles">
        <h3>Ciudades disponibles para servicios</h3>
      </div>

      <div className="newSource">
        <input type="text" placeholder="Nueva fuente" {...text("name")} />
        <FiPlusCircle onClick={saveSource} />
      </div>

      {data.map((item) => (
        <div className="wrapper-list" key={item._id}>
          <div className="list">
            <div>
              <p>{item.nombre}</p>
              <span className="del">
                <div onClick={() => deleteSource(item._id)}>
                  <GoTrashcan />
                </div>
              </span>
              <span className="user">Por: {item.usuario}</span>
            </div>
          </div>
        </div>
      ))}
    </CollectionsStyles>
  );
}
