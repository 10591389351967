import styled from 'styled-components';

const NavigationStyles = styled.div`

 @media (min-width: 900px) {
    display: none;
  }
  .navigation__checkbox {
    display: none;
  }

  .navigation__button {
    background-color: #fff;
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: 6rem;
    right: 6rem;
    border-radius: 50%;
    z-index: 200;
    box-shadow: 0 1rem 3rem ${props => props.theme.shadowDark};
    text-align: center;
    cursor: pointer;

    @media (max-width: 900px) {
      top: 4rem;
      right: 4rem;
    }

    @media (max-width: 600px) {
      top: 1rem;
      right: 3rem;
    }
    @media (min-width: 900px) {
      top: 2rem;
    }
  }
  .navigation__background {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    position: absolute;
    top: 6.5rem;
    right: 6.5rem;
    background-image: radial-gradient(
      ${props => props.theme.orange},
      ${props => props.theme.orange2}
    );
    z-index: 100;
    transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);

    @media (max-width: 900px) {
      top: 4.5rem;
      right: 4.5rem;
    }

    @media (max-width: 600px) {
      top: 1.2rem;
      right: 3.5rem;
    }
    @media (min-width: 900px) {
      top: 2.5rem;
    }
  }

  .navigation__nav {
    display: none;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 150;

    opacity: 0;
    width: 0;
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .navigation__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    width: 100%;
  }

  .navigation__item {
    margin: 1rem;
  }

  .navigation__link {
    &:link,
    &:visited {
      display: inline-block;
      font-size: 2.5rem;
      font-weight: 300;
      padding: 1rem 2rem;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.4s;

      span {
        margin-right: 1.5rem;
        display: inline-block;
      }
    }

    &:hover,
    &:active {
      transform: translateX(1rem);
    }
  }

  //FUNCTIONALITY
  .navigation__checkbox:checked ~ .navigation__background {
    transform: scale(80);
  }

  .navigation__checkbox:checked ~ .navigation__nav {
    opacity: 1;
    display: block;
    width: 100%;
  }
  .navigation__icon {
    position: relative;
    margin-top: 2.9rem;

    &,
    &::before,
    &::after {
      width: 2.3rem;
      height: 2px;
      background-color: ${props => props.theme.orange};
      display: inline-block;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::before {
      top: -0.8rem;
    }
    &::after {
      top: 0.8rem;
    }
  }

  //ICON

  .navigation__button:hover .navigation__icon::before {
    top: -1rem;
  }

  .navigation__button:hover .navigation__icon::after {
    top: 1rem;
  }

  .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent;
  }

  .navigation__checkbox:checked
    + .navigation__button
    .navigation__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }

 
`;


const HeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 2rem;
  padding-top: 2rem;

  img {
    width: auto;
    height: 9vw;
    cursor: pointer;
  }
  @media (min-width: 700px) {
    img {
      height: 3vw;
      cursor: pointer;
    }
  }
`;


const NavigationDesktopStyles = styled.div`

  @media (max-width: 900px) {
    display: none;
  }

  width: 100%;
  text-align: right;

  ul {
    margin-top: 0;
  }
  ul li {
    display: inline-block;

    a {
      padding: 4px 12px;
      margin-right: 1rem;
      font-size: 1.7rem;

      :hover {
        color: #d2711f;
      }

      svg {
        font-size: 3rem;
        position: relative;
        top: 8px;
      }
    }
  }
  
`;

const DashboardStyles = styled.div`
  .sentence {
    text-align: center;
    font-size: 2rem;
    letter-spacing: 1px;
  }
  .sentence:first-letter {
    text-transform: capitalize;
  }

  .services {
    h3 {
      font-size: 1.7rem;
      margin-top: 3rem;
    }
    .grid-boxes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      margin: 2rem 0;

      .completed {
        background: #63ab6f;
      }
      .process {
        background: #ffbe08;
      }

      div {
        padding: 1rem 1rem;
        color: #fff;
        border-radius: 4px;
        text-align: center;
        font-weight: bold;
        line-height: 3rem;

        span {
          display: block;
          font-size: 3rem;
          font-weight: normal;
        }
      }
    }
  }

  .see {
    display: inline-block;
    color: #ffffff;
    margin-top: 3rem;
    padding: 3px 20px;
    font-weight: normal;
    font-size: 2rem;
    text-align: center;
    background: linear-gradient(45deg, #b7580a, #e07721);
    border-radius: 4px;
  }
  .failedText {
    font-size: 2rem;
    text-align: center;
    color: #ff3434;
    font-weight: bold;
    margin-top: 5rem;
  }

  .has-collections {
    text-align: center;
    p {
      color: #ff5050;
      font-size: 2rem;
    }
    a {
      padding: 1rem 2rem;
      font-size: 2rem;
      border-radius: 7px;
      background: #eb6d07;
      color: #fff;
      border: none;
      font-weight: 100;
    }
  }
`;


export {
  NavigationStyles,
  HeaderStyles,
  NavigationDesktopStyles,
  DashboardStyles
} 
  