import styled from 'styled-components';

const AsignServiceStyles = styled.div`
  display: grid;

  text-align: center;
  justify-items: center;

  .upper {
    text-transform: uppercase;
  }
  .filter-select {
    margin-bottom: 5vh;
  }
  .inputText {
    
    background: #e4e4e4;
    border-radius: 3px;
    border: none;
    padding: 1.4rem 1.2rem;
    text-align: center;
    width: 100%;
    margin-bottom: 5vh;
    font-size: 18px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;

    label {
      font-size: 1.6rem;
      margin-top: 2rem;
      display: block;
      font-style: italic;
    }
  }
  .boxForm {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5rem;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
  .boxForm3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5rem;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
  textarea {
    background: #e4e4e4;
    border-radius: 3px;
    border: none;
    padding: 1.4rem 1.2rem;
    text-align: center;
    width: 100%;
    margin-bottom: 5vh;
    font-size: 18px;
    resize: none;
  }

  fieldset {
    border: none;
    background: #f9f9f9;
    padding: 3rem;
    box-shadow: 1px 1px 5px #c7c7c7dd;
    }


  img {
    width: 40vh;
    max-width: 23rem;
    margin-top: 5vh;
    margin-right: 3rem;
    transition: all 0.4s;
  }
  h3 {
    color: #333333;
    font-size: 1.8rem;
    font-style: italic;
    line-height: 21px;
    padding: 0 3rem;
    margin-bottom: 2vh;
    margin-top: 3.5vh;
  }

  h2 {
    font-size: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .assign {
    margin-top: 5vh;
  }
  .react-datepicker-wrapper {
    width: 100%;
    margin-bottom: 5vh;

    .react-datepicker__input-container {
      width: 100%;

      input {

        background: #e4e4e4;
        border-radius: 3px;
        border: none;
        padding: 1.4rem 1.2rem;
        text-align: center;
        width: 100%;
        font-size: 18px;
      }
    }
  }

  .datePicker {
    font-size: 1.4rem;

    .react-datepicker__day, .react-datepicker__day-name {
      width: 3rem;
      
    }
    .react-datepicker__day-name  {
      text-transform: capitalize;
    }
 
    .react-datepicker__current-month {
      font-size: 1.8rem;
      text-transform: capitalize;
      color: #d3701f;
    }
    .react-datepicker-time__header {
      font-size: 1.5rem;
      color: #d3701f;
    }

    .react-datepicker__navigation--previous {
      border-right-color: #d2711f;
    }
    .react-datepicker__navigation--next {
      border-left-color: #d2711f;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
      background-color: #d2711f;
    }
    .react-datepicker__day--selected {
      background-color: #d2711f;
    }
  }


`;

const ButtonPrimary = styled.button`
  background: linear-gradient(45deg, #b15f1c, #d3701f);
  border-radius: 4px;
  border: 1px solid #ad4d00;
  color: #fff;
  padding: 1.4rem 6rem;
  font-size: 2rem;
  transition: all 0.3s;
  width: ${props => (props.login ? 'auto' : '100%')};

  &:hover {
    background-color: ${props => props.theme.orangeHover};
  }

  &[disabled] {
    opacity: 0.5;
  }
`;

const InputText = styled.input`
  background: #e4e4e4;
  border-radius: 3px;
  border: none;
  padding: 1.4rem 1.2rem;
  text-align: center;
  width: 100%;
  margin-bottom: 5vh;
  font-size: 18px;
`;

const InputSelect = styled.select`
   background: #e4e4e4;
  border-radius: 3px;
  border: none;
  padding: 1.4rem 1.2rem;
  text-align: center;
  width: 100%;
  margin-bottom: 5vh;
  font-size: 18px;
  height: 5rem;
  text-align-last: center;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  background: #e4e4e4;
  border-radius: 3px;
  border: none;
  padding: 1.4rem 1.2rem;
  text-align: center;
  width: 100%;
  margin-bottom: 5vh;
  font-size: 1.6rem;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
`;



export {
    AsignServiceStyles,
    ButtonPrimary,
    InputText,
    InputSelect,
    TextArea
};
