import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useFormState } from "react-use-form-state";
import { AuthContext } from "../../context";
import { LoginStyles, ButtonPrimary, InputLogin } from "./LoginStyle";

export default function App() {
  const [formState, { email, password }] = useFormState({});
  const { signIn } = React.useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    fetch("https://backendsapco.herokuapp.com/sapco/login", {
      method: "POST",
      body: JSON.stringify(formState.values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        //console.log(response)
        if (response.error) {
          toast.error("Ups, no parece existir este usuario", {
            autoClose: 1000,
          });
        } else {
          localStorage.setItem("sapcoUser", JSON.stringify(response));
          signIn();
        }
      });
  };

  return (
    <LoginStyles>
      <ToastContainer />
      <img src="images/logonuevo.png" alt="Logo Sapco" />

      <h3>Salvando al mundo de accidentes de tránsito </h3>

      <form method="post" onSubmit={handleSubmit}>
        <InputLogin
          {...email("email")}
          placeholder="Email"
          autoCapitalize="off"
          spellCheck={false}
          required
        />
        <InputLogin
          {...password("contrasena")}
          placeholder="Contraseña"
          autoComplete="off"
          required
        />
        <ButtonPrimary login> Entrar </ButtonPrimary>
      </form>
    </LoginStyles>
  );
}
