import React from "react";
import { Link,  } from "react-router-dom";
import { AuthContext } from "../../context";

export default function Home() {
  const { signOut } = React.useContext(AuthContext);
  return (
    <>
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src="./images/logonuevo.png" alt="logo" />
          </Link>
        </div>
        <div className="navbar">
          {/*<div className="icon-bar" onClick="Show()">
            <i></i>
            <i></i>
            <i></i>
          </div>*/}

          <ul id="nav-lists">
            {/*<li className="close">
              <span onClick="Hide()">×</span>
            </li>*/}
            <li>
              <Link to="/asignar-servicio">Asignar Servicio</Link>
            </li>
            <li>
              <Link to="/servicios">Lista de servicios</Link>
            </li>
            {/* <li>
              <Link to="/">Agenda</Link>
            </li> */}
            <li>
              <Link to="/usuarios">Usuarios</Link>
            </li>
            {/*<li>
              <Link to="/colecciones">Empresa</Link>
            </li>*/}
            <li>
              <Link to="/colecciones">Colecciones</Link>
            </li>
            <li>
              <Link to="/registro">Registrar</Link>
            </li>
            <li>
            </li>
            <li>
              <div style={{marginLeft:'100%', marginTop:'35%', cursor:'pointer'}}
              onClick={()=>signOut()}>SALIR</div>
            </li>
          </ul>
        </div>
      </div>
      
    </>
  );
}
