import React, { useState, useEffect } from "react";
import {
  AsignServiceStyles,
  ButtonPrimary,
  InputText,
  InputSelect,
  TextArea,
} from "./style";
import { ToastContainer, toast } from "react-toastify";
import { useFormState } from "react-use-form-state";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import brands from "../Libs/Brands";

export default function AsignarServicio() {
  const [cities, setCities] = useState([]);
  const [habilitarkm, sethablitarkm] = useState(false);
  const [serviceList, setserviceList] = useState([]);
  const [fuente, setfuente] = useState([]);
  const [driver, setdriver] = useState([]);
  const [pickUpTime, setPickUpTime] = useState();
  const [totalKm, setTotalKm] = useState();
  const [formState, { text, select, number, textarea }] = useFormState({});

  useEffect(()=>{
    if (formState.values.local==="Foráneo"){
      sethablitarkm(true)
    }else{
      sethablitarkm(false)
    }
  },[formState.values.local])

  const pickUpChange = async (date) => {
    setPickUpTime(date);
  };

  const handleSubmit = async (e) => {

    let dataSend = {
      ...formState.values,
      horaRecogida: pickUpTime.toLocaleDateString('es-ES') + " " + pickUpTime.toLocaleTimeString('es-ES')
    }

    fetch("https://backendsapco.herokuapp.com/sapco/services", {
      method: "POST",
      body: JSON.stringify(dataSend),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          toast.error("Ups, Error al registrar servicio", {
            autoClose: 1000,
          });
        } else {
          toast.success('Servicio registrado satisfactoriamente', {
            autoClose: 3000,
            onClose: () => window.location = "/servicios",
          });
        }
      });
  };

  useEffect(() => {
    fetch("https://backendsapco.herokuapp.com/sapco/users-drivers", {
      method: "POST",
      body: JSON.stringify({ciudad:formState.values.ciudad}),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log("Ups, error");
        } else {
          setdriver(response)
        }
      });
  }, [formState.values.ciudad]);

  useEffect(() => {
    fetch("https://backendsapco.herokuapp.com/sapco/ciudades")
    .then((response) => response.json())
    .then((json) => setCities(json));

    fetch("https://backendsapco.herokuapp.com/sapco/servicios")
      .then((response) => response.json())
      .then((json) => setserviceList(json));

      fetch("https://backendsapco.herokuapp.com/sapco/fuentes")
      .then((response) => response.json())
      .then((json) => setfuente(json));
  }, []);


  return (
    <AsignServiceStyles>
      <ToastContainer />
      <form onSubmit={async (e) => {
          e.preventDefault();
          handleSubmit();
        }}>
        <fieldset className="fieldset">
          <div className="boxForm">
            <div>
              <h3>Información del cliente</h3>
              <InputText
                {...text("nombreCliente")}
                placeholder="Nombre del cliente"
                required
              />
              <InputText
                {...text("numeroContacto")}
                placeholder="Número de contacto"
                required
              />
              <InputText
                {...text("origen")}
                placeholder="Origen"
                required
              />
              <InputText
                {...text("destino")}
                placeholder="Destino"
                required
              />

              <InputSelect {...select("marca")} required>
                <option value="">Selecciona una marca</option>
                {brands.map((item, i) => (
                  <option key={i} value={item}>{item}</option>
                ))}
              </InputSelect>

              <InputText {...text("linea")} placeholder="Linea" required />
            </div>
            <div>
              <h3>Información adicional</h3>
              <InputSelect
                {...select("ciudad")}
                required
              >
                <option value="">Ciudad</option>
                {cities &&
                  cities.map((city) => {
                    return (
                      <option
                        key={city._id}
                        value={city.nombre}
                        data-cityid={city._id}
                      >
                        {city.nombre}
                      </option>
                    );
                  })}
              </InputSelect>
              <InputSelect {...select("local")} required>
                <option value="">Es local ó foráneo ?</option>
                <option value="Local">Local</option>
                <option value="Foráneo">Foráneo</option>
              </InputSelect>
              {habilitarkm?(
                <InputText
                {...number("km")}
                placeholder="KM"
                required
              />
              ):null}
              <InputSelect
                {...select("tipoServicio")}
                required
              >
                <option value="">Tipo de Servicio</option>
                {serviceList &&
                  serviceList.map((service) => {
                    return (
                      <option
                        key={service._id}
                        value={service.nombre}
                        data-cityid={service._id}
                      >
                        {service.nombre}
                      </option>
                    );
                  })}
              </InputSelect>
            </div>
            <div>
              <h3>Información del servicio</h3>

              <DatePicker
                calendarClassName="datePicker"
                placeholderText="Hora de recogida"
                selected={pickUpTime}
                locale="es"
                timeCaption="Hora"
                onChange={pickUpChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MMMM d, yyyy h:mm aa"
                required
              />

              <InputText
                className="upper"
                {...text("expediente")}
                placeholder="Expediente"
                required
              />
              <InputText
                className="upper"
                {...text("placa")}
                placeholder="Placa"
                maxLength={6}
                required
              />
              <InputSelect
                {...select("conductorAsignado")}
                required
              >
                <option value="">Conductor</option>
                {driver &&
                  driver.map((city) => {
                    return (
                      <option
                        key={city._id}
                        value={city.nombre}
                        data-cityid={city._id}
                      >
                        {city.nombre}
                      </option>
                    );
                  })}
              </InputSelect>
              <InputText
                {...number("valor")}
                placeholder="Valor (Opcional)"
              />

              <TextArea
                {...textarea("observaciones")}
                placeholder="Observaciones - Uso interno"
              />
            </div>
          </div>
        </fieldset>

        <ButtonPrimary className="assign" login>
          Asignar
        </ButtonPrimary>
      </form>
    </AsignServiceStyles>
  );
}
