import styled from "styled-components";

const LoginStyles = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  text-align: center;
  justify-items: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 40vh;
    max-width: 23rem;
    margin-top: 5vh;
    margin-right: 3rem;
    transition: all 0.4s;
  }
  h3 {
    color: #333333;
    font-size: 1.8rem;
    font-style: italic;
    line-height: 21px;
    padding: 0 3rem;
    margin-bottom: 5vh;
    margin-top: 3.5vh;
  }
  button {
    margin-top: 5vh;
  }
`;

const ButtonPrimary = styled.button`
  background: linear-gradient(45deg, #b15f1c, #d3701f);
  border-radius: 4px;
  border: 1px solid #ad4d00;
  color: #fff;
  padding: 1.4rem 6rem;
  font-size: 2rem;
  transition: all 0.3s;
  width: ${(props) => (props.login ? "auto" : "100%")};

  &[disabled] {
    opacity: 0.5;
  }
`;

const InputLogin = styled.input`
  background: #e4e4e4;
  border-radius: 3px;
  border: none;
  padding: 1.4rem 1.2rem;
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 4vh;
  width: 62vw;
`;

export { LoginStyles, ButtonPrimary, InputLogin };
