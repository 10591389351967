import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";

import { AuthContext } from "./context";
import Login from "./components/Login/Login";
import Register from "./components/Login/Register";
import Home from "./components/Dashboard/Home";
import Menu from "./components/Dashboard/Menu";
import AsignarServicio from "./components/Dashboard/AsignarServicio/AsignarServicio";
import VerServicios from './components/Dashboard/Servicios/Servicios'
import Colecciones from './components/Dashboard/Colecciones/Colecciones'
import Fuentes from './components/Dashboard/Colecciones/Fuentes'
import Frases from './components/Dashboard/Colecciones/Frases'
import Servicios from './components/Dashboard/Colecciones/Servicios'
import Usuarios from './components/Dashboard/Usuarios/Usuarios'
import Ciudades from './components/Dashboard/Colecciones/Ciudades'



export default function App() {
  const [token, setToken] = useState('inicial');
  const [ruta, setruta] = useState(window.location.pathname);

  useEffect(() => {
    getStorageValue();
  }, []);

  function getStorageValue() {
    setruta(window.location.pathname)
    try {
      const value = JSON.parse(localStorage.getItem("sapcoUser"));
      if (value === null) {
        setToken(false);
      }else{
        setToken(true);
      }
    } catch (e) {
      // handle here
    }
  }

  const authContext = React.useMemo(() => {
    return {
      signIn: () => {
        setToken(true);
        window.location = "/";
      },
      signOut: () => {
        localStorage.removeItem("sapcoUser");
        setToken(false);
        window.location = "/login";
      },
      singInAdmin:()=>{
        setToken("admin");
      }
    };
  }, []);

 /* const NotFound = () =>{
  return (<div>Hola</div>)
  <Route component={NotFound} />
}*/

  const Validacion = () => {
    return (
      <Router>
        <Switch>
          {token === true ? (
            <>
              <Menu />
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/menu" exact>
                <Menu />
              </Route>
              <Route path="/asignar-servicio" exact>
                <AsignarServicio />
              </Route>
              <Route path="/servicios" exact>
                <VerServicios />
              </Route>
              <Route path="/usuarios" exact>
                <Usuarios />
              </Route>
              <Route path="/colecciones" >
                <Colecciones />
              </Route>
              <Route path="/colecciones/frases" exact>
                <Frases />
              </Route>
              <Route path="/colecciones/servicios" exact>
                <Servicios />
              </Route>
              <Route path="/colecciones/ciudades" exact>
                <Ciudades />
              </Route>
              <Route path="/registro">
                <Register />
              </Route>
              
            </>
          ) : token === false ? (
            <>
              <Route path="/login">
                <Login />
              </Route>
              <Redirect to="/login"/>
            </>
          ): null 
        }
        </Switch>
      </Router>
    );
  };

  return (
    <AuthContext.Provider value={authContext}>
      {token ==="inicial" ? <p>Cargando</p>:
        <Validacion />
      }
    </AuthContext.Provider>
  );
}
